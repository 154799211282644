<template>
  <div class="__bg">
    <div v-if="!loading">
      <div v-if="recap">
        <header class="pa-3">
          <h1>Progres Peserta Teladan</h1>
        </header>
        <div class="pa-3 bio d-flex _80">
          <section class="_half">
            <v-row>
              <v-col cols="2">
                <div class="d-flex align-center" style="height: 100%">
                  <label for="input">Nama</label>
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="d-flex align-center">
                  <span class="mr-2">:</span>
                  <v-text-field
                    solo
                    dense
                    readonly
                    type="search"
                    autocomplete="off"
                    class="mr-2"
                    label="Cari nama"
                    rounded
                    hide-details
                    flat
                    v-model="recap.profile.nama_lengkap"
                    background-color="#ECEFF1"
                  ></v-text-field>
                  <!-- <v-btn color="black" fab depressed dark x-small>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn> -->
                </div>
              </v-col>
            </v-row>
          </section>
          <!-- <section class="_half d-flex align-center justify-end">
            <div>
              <v-btn color="black" fab depressed dark x-small class="mr-2">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn color="black" fab depressed dark x-small>
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </section> -->
        </div>
        <div class="pa-3 bio d-flex justify-space-between _80">
          <section class="d-flex">
            <div style="width: 100px">Kampus</div>
            <div>: {{ recap.profile.universitas }}</div>
          </section>
          <section class="d-flex">
            <div style="width: 120px">Tanggal Lahir</div>
            <div>: {{ recap.profile.tgl_lahir }}</div>
          </section>
          <section class="d-flex">
            <div style="width: 120px">Jenis kelamin</div>
            <div>: {{ recap.profile.jenis_kelamin }}</div>
          </section>
        </div>
        <div class="pa-3 _80">
          <v-card class="pa-2 radius_card" color="black" dark>
            <v-row>
              <v-col cols="2" md="1"><p class="ma-0 text-center">N0</p></v-col>
              <v-col cols="10" md="6"
                ><p class="ma-0 text-center">VALUE & COMPETENCY</p></v-col
              >
              <v-col cols="12" md="5"
                ><p class="ma-0 text-center">PROGRESS SCORE</p></v-col
              >
            </v-row>
          </v-card>
        </div>
        <div class="d-flex" recap>
          <div class="pa-3 _80">
            <v-card class="radius_card card_like_table">
              <v-row v-for="(item, idx, counter) in dataProgress" :key="idx">
                <v-col cols="1" md="1" class="pa-0"
                  ><div
                    class="
                      _full_border
                      py-7
                      px-3
                      d-flex
                      align-center
                      justify-center
                    "
                    style="height: 100%"
                  >
                    <p class="ma-0 text-center">{{ counter + 1 }}</p>
                  </div></v-col
                >
                <v-col cols="11" md="6" class="pa-0"
                  ><div class="_full_border py-7 px-3" id="integrity">
                    <h3>{{ item.title }}</h3>
                    <p class="ma-0">
                      {{ item.deskripsi }}
                    </p>
                  </div></v-col
                >
                <v-col cols="12" md="5" class="pa-0"
                  ><div
                    class="_full_border d-flex align-end py-3 px-3"
                    v-if="item.requirement"
                  >
                    <apexchart
                      height="200"
                      type="area"
                      :options="item.chart.options"
                      :series="item.chart.series"
                    ></apexchart></div
                ></v-col>
              </v-row>
            </v-card>
          </div>
          <div class="pa-3 _20"></div>
        </div>
      </div>
    </div>
    <div class="pa-3" v-if="loading">
      <LoadingLG />
    </div>
    <div
      v-if="!recap && !loading"
      class="d-flex justify-center align-center flex-column"
    >
      <img src="../../assets/img/notFound.png" height="300px" alt="" />
      <h1>Tidak Ada Data</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingLG from "../../components/etc/loading-component/loadingLG.vue";
export default {
  components: { LoadingLG },
  name: "progressTeladan",
  computed: {
    ...mapState({
      recap: (state) => state.report.recap,
    }),
    dataProgress() {
      let result = null;

      if (this.recap) {
        result = this.recap.result;
        for (let key in result) {
          result[key]["title"] = key.replace(/_/g, " ").toUpperCase();
          let act = [];
          let req = [];
          let tool = {};
          let tool2 = {};
          for (let i in result[key]["score"]) {
            act.push(result[key]["score"][i].value);
            tool[result[key]["score"][i].value] =
              result[key]["score"][i].deskripsi;
          }
          if (result[key]["requirement"]) {
            result[key]["requirement"].forEach((j) => {
              req.push(j.requirement);
              tool2[j.requirement] = j.deskripsi;
            });
          }
          let options = {
            chart: {
              id: "area",
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: [0],
              custom: [
                // eslint-disable-next-line
                function ({ series, seriesIndex, dataPointIndex, w }) {
                  return (
                    '<div class="arrow_box">' +
                    '<span style="display: inline-block;">' +
                    series[seriesIndex][dataPointIndex] +
                    `: ${
                      tool2[series[seriesIndex][dataPointIndex]]
                        ? tool2[series[seriesIndex][dataPointIndex]]
                        : "-"
                    }` +
                    "</span>" +
                    "</div>"
                  );
                },
                // eslint-disable-next-line
                function ({ series, seriesIndex, dataPointIndex, w }) {
                  return (
                    '<div class="arrow_box">' +
                    '<span style="display: inline-block;">' +
                    series[seriesIndex][dataPointIndex] +
                    `: ${
                      tool[series[seriesIndex][dataPointIndex]]
                        ? tool[series[seriesIndex][dataPointIndex]]
                        : "-"
                    }` +
                    "</span>" +
                    "</div>"
                  );
                },
              ],
            },
            stroke: {
              curve: "smooth",
            },
            markers: {
              size: 5,
            },
            colors: ["#CED4DC", "#00E396"],
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Baseline", "Midline", "Endline"],
            },
            yaxis: [
              {
                tickAmount: 5,
                min: 0,
                max: 5,
              },
            ],
          };
          let series = [
            {
              name: "Requirement",
              data: req,
            },
            {
              name: "Actual",
              data: act,
            },
          ];
          result[key]["chart"] = {
            options: options,
            series: series,
          };
        }
      }
      return result;
    },
  },
  data() {
    return {
      name: "Farida Hayu Pramesthi",
      heightOfIntegrity: 0,
      heightOfSfe: 0,
      heightOfGrit: 0,
      heightOfCaring: 0,
      heightOfSelfAwarness: 0,
      loading: false,
    };
  },
  mounted() {
    this.getRecap();
  },
  methods: {
    getRecap() {
      this.loading = true;
      this.$store
        .dispatch("report/viewRecap", { id_scholar: this.$route.params.id })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getHeight() {
      this.heightOfIntegrity =
        document.querySelector("#integrity").offsetHeight;
      this.heightOfSfe = document.querySelector("#sfe").offsetHeight;
      this.heightOfGrit = document.querySelector("#grit").offsetHeight;
      this.heightOfCaring = document.querySelector("#caring").offsetHeight;
      this.heightOfSelfAwarness =
        document.querySelector("#selfAwarness").offsetHeight;
    },
  },
};
</script>

<style socped>
.__bg {
  width: 100%;
  min-height: 90vh;
}
._80 {
  width: 80%;
}
._20 {
  width: 20%;
}
._half {
  width: 50%;
}
.radius_card {
  border-radius: 10px !important;
}
._full_border {
  border: solid 0.5px #f5f5f5;
  height: 100%;
}
.card_like_table {
  overflow: hidden;
}
#apexchartsarea {
  max-width: 760px;
  margin: 35px auto;
  opacity: 0.9;
}

.arrow_box {
  position: relative;
  width: 200px;
  z-index: 2;
}

#apexchartsarea .apexcharts-tooltip {
  transform: translateX(10px) translateY(0);
  overflow: visible !important;
  white-space: normal !important;
}

#apexchartsarea .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}
</style>
