<template>
  <div class="d-flex align-center flex-column">
    <svg
      width="263"
      height="249"
      viewBox="0 0 263 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="V.1 0721 Loading Icon 1">
        <path
          id="Shade"
          opacity="0.2"
          d="M134.306 240.049C173.08 240.049 204.512 230.577 204.512 218.892C204.512 207.208 173.08 197.735 134.306 197.735C95.5329 197.735 64.1008 207.208 64.1008 218.892C64.1008 230.577 95.5329 240.049 134.306 240.049Z"
          fill="black"
        />
        <g id="toga">
          <g id="Layer 1">
            <path
              id="Vector"
              d="M200.384 170.409L146.348 191.138C138.554 194.13 130.058 194.13 122.265 191.138L68.2284 170.409C67.1044 169.979 66.131 169.178 65.4427 168.118C64.7544 167.057 64.3853 165.79 64.3866 164.491V111.204C64.3866 109.541 64.9917 107.947 66.069 106.77C67.1463 105.593 68.6078 104.931 70.1325 104.929H198.472C199.998 104.929 201.462 105.59 202.541 106.767C203.62 107.944 204.226 109.54 204.226 111.204V164.491C204.225 165.789 203.855 167.055 203.167 168.115C202.479 169.175 201.507 169.977 200.384 170.409V170.409Z"
              fill="#024929"
            />
            <path
              id="Vector_2"
              d="M70.1325 104.929C68.6078 104.931 67.1463 105.593 66.069 106.77C64.9917 107.947 64.3866 109.541 64.3866 111.204V164.491C64.3853 165.79 64.7544 167.057 65.4427 168.118C66.131 169.178 67.1044 169.979 68.2284 170.409L122.265 191.138C126.134 192.617 130.203 193.371 134.302 193.371V104.929H70.1325Z"
              fill="#08512E"
            />
            <path
              id="Vector_3"
              d="M243.819 111.277L135.787 148.591C134.823 148.92 133.79 148.92 132.826 148.591L24.7943 111.277C23.7997 110.932 22.9314 110.251 22.3152 109.333C21.699 108.414 21.3669 107.306 21.3669 106.168C21.3669 105.029 21.699 103.921 22.3152 103.002C22.9314 102.084 23.7997 101.403 24.7943 101.058L132.826 63.7534C133.79 63.4238 134.823 63.4238 135.787 63.7534L243.819 101.058C244.813 101.403 245.681 102.084 246.298 103.002C246.914 103.921 247.246 105.029 247.246 106.168C247.246 107.306 246.914 108.414 246.298 109.333C245.681 110.251 244.813 110.932 243.819 111.277V111.277Z"
              fill="#03603A"
            />
            <path
              id="Vector_4"
              d="M132.826 63.7542L24.7943 101.059C23.7997 101.404 22.9314 102.085 22.3152 103.003C21.699 103.922 21.3669 105.03 21.3669 106.168C21.3669 107.306 21.699 108.415 22.3152 109.334C22.9314 110.252 23.7997 110.933 24.7943 111.277L132.826 148.592C133.304 148.755 133.801 148.839 134.302 148.839V63.4706C133.8 63.4824 133.302 63.578 132.826 63.7542V63.7542Z"
              fill="#056641"
            />
            <path
              id="Vector_5"
              d="M244.289 101.27C244.136 101.349 243.979 101.419 243.819 101.48L135.788 138.785C134.825 139.124 133.789 139.124 132.826 138.785L24.7949 101.48C24.6349 101.419 24.4781 101.349 24.3252 101.27C23.4092 101.704 22.6366 102.433 22.1105 103.358C21.5844 104.284 21.3298 105.363 21.3809 106.451C21.432 107.539 21.7862 108.584 22.3962 109.446C23.0062 110.309 23.8431 110.947 24.7949 111.277L132.826 148.592C133.79 148.921 134.824 148.921 135.788 148.592L243.819 111.277C244.771 110.947 245.608 110.309 246.218 109.446C246.828 108.584 247.182 107.539 247.233 106.451C247.284 105.363 247.03 104.284 246.504 103.358C245.977 102.433 245.205 101.704 244.289 101.27V101.27Z"
              fill="#024C2A"
            />
            <path
              id="Vector_6"
              d="M132.826 138.785L24.7949 101.48C24.6349 101.419 24.478 101.349 24.3251 101.27V101.27C23.7426 101.533 23.2141 101.92 22.7705 102.408C22.3268 102.897 21.9771 103.477 21.7416 104.115V104.115C21.6603 104.348 21.5903 104.586 21.5319 104.828C21.5319 104.828 21.5319 104.828 21.5319 104.892C21.4861 105.119 21.4525 105.348 21.4312 105.578C21.427 105.621 21.427 105.664 21.4312 105.706C21.4312 105.914 21.4312 106.124 21.4312 106.338C21.435 106.405 21.435 106.472 21.4312 106.539C21.4379 106.729 21.4575 106.919 21.4899 107.106L21.4983 107.38C21.5285 107.544 21.5678 107.706 21.6157 107.865C21.6157 107.975 21.6912 108.076 21.7248 108.185C21.7583 108.295 21.8255 108.46 21.8842 108.597C21.9429 108.734 22.0016 108.826 22.0687 108.936C22.1251 109.056 22.1895 109.173 22.2616 109.283C22.3371 109.402 22.4294 109.512 22.5217 109.631C22.587 109.726 22.657 109.818 22.7314 109.905C22.8404 110.024 22.9662 110.134 23.0837 110.244L23.3186 110.463C23.4612 110.573 23.6205 110.674 23.7799 110.774L24.0064 110.921C24.2584 111.064 24.5224 111.18 24.7949 111.268L132.826 148.583C133.304 148.746 133.802 148.83 134.303 148.829V139.041C133.801 139.043 133.303 138.956 132.826 138.785V138.785Z"
              fill="#045633"
            />
            <g id="Group" opacity="0.4">
              <path
                id="Vector_7"
                opacity="0.4"
                d="M175.589 179.923L172.234 181.222V128.311L132.348 108.396C130.67 107.554 130.217 101.27 131.509 99.4675C132.348 98.3606 134.093 101.48 134.696 101.544C134.956 101.544 136.718 102.532 137.213 102.788L176.965 122.63C177.55 122.921 178.047 123.39 178.395 123.98C178.743 124.571 178.928 125.258 178.927 125.96V175.889C178.944 177.911 177.384 179.228 175.589 179.923Z"
                fill="#042B18"
              />
            </g>
            <path
              id="Vector_8"
              d="M179.33 203.817C182.953 203.817 185.89 200.66 185.89 196.764C185.89 192.869 182.953 189.711 179.33 189.711C175.707 189.711 172.77 192.869 172.77 196.764C172.77 200.66 175.707 203.817 179.33 203.817Z"
              fill="#B3A369"
            />
            <path
              id="Vector_9"
              d="M188.498 220.339H170.162C170.162 220.339 171.344 200.524 179.33 200.524C187.316 200.524 188.498 220.339 188.498 220.339Z"
              fill="#B3A369"
            />
            <path
              id="Vector_10"
              d="M134.302 101.27L179.33 123.746V201.613"
              stroke="#B3A369"
              stroke-width="8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>

    <h2>Loading ...</h2>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#toga {
  animation: toga infinite ease-in-out alternate 0.5s;
  transform-origin: bottom;
  animation-delay: none;
}
#Shade {
  animation: shade infinite ease-in-out alternate 0.5s;
  transform-origin: bottom;
  animation-delay: none;
}

@keyframes toga {
  0% {
    transform: translate(0) ;
  }

  100% {
    transform: translate(0, -20%);
  }
}
@keyframes shade {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
</style>